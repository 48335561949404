/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Table.css";
import tabFirst from "../../../../../assests/colar.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../../../Store/Store";
import { RxCross1 } from "react-icons/rx";
import { FiLoader } from "react-icons/fi";
import Avatar from "react-avatar";

const Table = (props) => {
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const bookmarkdata = useSelector((store) => store.bookmarkdata);

  const [isSelect, setIsSelect] = useState("Shortlisted");
  const [alluserdata, setalluserdata] = useState([]);
  const [tabledata, settabledata] = useState([]);
  const [loading, setloading] = useState(false);
  const [singleuser, setsingleuser] = useState([]);
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [timezone, settimezone] = useState("");

  useEffect(() => {
    getUserinfo();
  }, [bookmarkdata]);

  const getUserinfo = async () => {
    if (bookmarkdata.length !== 0) {
      var unique = bookmarkdata.filter(
        (value, index, array) => array.indexOf(value) === index
      );
      let data = JSON.stringify({
        users_list: unique,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/getUsersInformation/${userid}/`,
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data.length !== 0) {
            setalluserdata(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            dispatch(storeAction.isloginHandler({ islogin: false }));
            dispatch(storeAction.issidebarHandler({ issidebar: false }));
            dispatch(storeAction.tokenHandler({ token: null }));
            dispatch(storeAction.useridHandler({ userid: null }));
            dispatch(storeAction.isPopUpHander(""));
            dispatch(storeAction.searchuserHander({ searchuser: [] }));
            dispatch(storeAction.singleuserHander({ singleuser: [] }));
            dispatch(storeAction.userdataHander({ userdata: [] }));
            dispatch(storeAction.loginroleHander({ islogin: null }));
            dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
            dispatch(storeAction.contractdataHander({ contractdata: [] }));
            window.location.replace("/#/login");
          } else {
            dispatch(storeAction.isloginHandler({ islogin: false }));
            dispatch(storeAction.issidebarHandler({ issidebar: false }));
            dispatch(storeAction.tokenHandler({ token: null }));
            dispatch(storeAction.useridHandler({ userid: null }));
            dispatch(storeAction.isPopUpHander(""));
            dispatch(storeAction.searchuserHander({ searchuser: [] }));
            dispatch(storeAction.singleuserHander({ singleuser: [] }));
            dispatch(storeAction.userdataHander({ userdata: [] }));
            dispatch(storeAction.loginroleHander({ islogin: null }));
            dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
            dispatch(storeAction.contractdataHander({ contractdata: [] }));
            window.location.replace("/#/login");
          }
          return err.response;
        });

      var config1 = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      var tabledata = await axios(config1)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });
      settabledata(tabledata);
    }
  };
  const removebtn = async (id) => {
    var checkdata = await tabledata.filter((data) => {
      return data.bookmarked_user == id;
    });
    if (checkdata.length !== 0) {
      var config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/${checkdata[0].id}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
      await axios(config)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });

      var config1 = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
        headers: {
          Authorization: `JWT ${token}`,
        },
      };

      var table_data = await axios(config1)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          return error;
        });

      if (table_data.length !== 0) {
        const bookmarkedUserArray = table_data.map(
          (item) => item.bookmarked_user
        );
        dispatch(
          storeAction.bookmarkdataHander({ bookmarkdata: bookmarkedUserArray })
        );
      } else {
        dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
      }
    }
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const overLayHandler = (e, data) => {
    dispatch(storeAction.isPopUpHander(e));
    setsingleuser([data]);
  };
  const submitbtn = async () => {
    if (singleuser.length !== 0) {
      setloading(true);
      var obj = {
        interview_info: {
          date: date,
          time: time,
          candidate: singleuser[0].id,
          meeting_url: "null",
          time_zone: timezone,
        },
      };

      await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/getInterview/${userid}/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      setloading(false);
      dispatch(storeAction.isPopUpHander());
      var userinfo = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (userinfo !== undefined) {
        if (userinfo.id !== undefined) {
          dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
        }
      }
    }
  };
  return (
    <div>
      {bookmarkdata.length !== 0 ? (
        <div className={props.class}>
          <h1>Candidates on review</h1>
          <div className="tableButton marginTop20 marginBottom20">
            <button
              onClick={() => {
                setIsSelect("Shortlisted");
              }}
              className={
                isSelect === "Shortlisted"
                  ? "shortListedActive"
                  : "shortListedInActive"
              }
            >
              Shortlisted
            </button>
            <button
              onClick={() => {
                setIsSelect("Hired");
              }}
              className={
                isSelect === "Hired"
                  ? "shortListedActive"
                  : "shortListedInActive"
              }
            >
              Hired
            </button>
          </div>
          {isSelect === "Shortlisted" ? (
            <div className="innerTable">
              <table className="table">
                <tr className="tableHead">
                  <th className="tableFirst"></th>
                  <th>Candidate Name</th>
                  <th>Qualification</th>
                  <th>Experience</th>
                  <th>Key Skills</th>
                  <th></th>
                </tr>
                {alluserdata.length !== 0
                  ? alluserdata.map((data, index) => {
                      return (
                        <tr className="tableRow" key={index}>
                          <td
                            className="profileBookMark"
                            onClick={() => {
                              removebtn(data.id);
                            }}
                          >
                            <img src={tabFirst} alt="" />
                          </td>
                          <td>
                            <div className="profileData">
                              {data.profile_picture.length !== 0 ? (
                                <img
                                  src={data.profile_picture}
                                  alt=""
                                  className="mr-2 tableimg"
                                />
                              ) : (
                                <Avatar
                                  name={data.first_name}
                                  size={30}
                                  round="50px"
                                  className="mr-2"
                                />
                              )}
                              <h2>{data.first_name}</h2>
                            </div>
                          </td>
                          {data.preference_info !== null ? (
                            <>
                              {" "}
                              <td>
                                <h2>{data.preference_info.qualification}</h2>
                              </td>
                              <td>
                                <h2>
                                  {data.preference_info.year_of_experience}{" "}
                                  years
                                </h2>
                              </td>
                              <td className="skillData">
                                {data.preference_info.skills.length !== 0
                                  ? data.preference_info.skills.map(
                                      (data, index) =>
                                        index == 0 ||
                                        index == 1 ||
                                        index == 2 ? (
                                          <p key={index}>{data}</p>
                                        ) : null
                                    )
                                  : "-"}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>-</td> <td>-</td> <td>-</td>
                            </>
                          )}

                          <td>
                            <div>
                              {data.block_expiry == null ? (
                                <button
                                  onClick={() => {
                                    overLayHandler("scheduleinterview", data);
                                  }}
                                  className="tdBtn"
                                >
                                  Schedule interview
                                </button>
                              ) : (
                                <button
                                  // onClick={() => {
                                  //   overLayHandler("scheduleinterview", data);
                                  // }}
                                  className="tdBtndisble"
                                >
                                  Schedule interview
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
            </div>
          ) : null}
        </div>
      ) : null}
      {isPopUp == "scheduleinterview" && (
        <>
          <div className="adminEditOverlay1">
            <div className="adminEditOverlayHead">
              <h1>Schedule Interview</h1>
              <RxCross1
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              />
            </div>
            <div className="adminEditOverlayBody">
              <div className="adminEditOverlayContent">
                <h2>Interview Date </h2>
                <input
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                />
              </div>
              <div className="adminEditOverlayContent">
                <h2>Interview Time</h2>
                <input
                  type="time"
                  onChange={(e) => {
                    settime(e.target.value);
                  }}
                />
              </div>
              <div className="adminEditOverlayContent">
                <h2>Time Zone</h2>
                <select
                  type="text"
                  onChange={(e) => {
                    settimezone(e.target.value);
                  }}
                  name="time_zone"
                >
                  <option value="">Select</option>
                  <option value="Pacific/Midway">
                    (GMT-11:00) Pacific/Midway
                  </option>
                  <option value="Pacific/Honolulu">
                    (GMT-10:00) Pacific/Honolulu
                  </option>
                  <option value="America/Los_Angeles">
                    (GMT-08:00) America/Los_Angeles
                  </option>
                  <option value="America/Denver">
                    (GMT-07:00) America/Denver
                  </option>
                  <option value="America/Chicago">
                    (GMT-06:00) America/Chicago
                  </option>
                  <option value="America/New_York">
                    (GMT-05:00) America/New_York
                  </option>
                  <option value="America/Buenos_Aires">
                    (GMT-03:00) America/Buenos_Aires
                  </option>
                  <option value="America/Sao_Paulo">
                    (GMT-03:00) America/Sao_Paulo
                  </option>
                  <option value="Atlantic/Azores">
                    (GMT-01:00) Atlantic/Azores
                  </option>
                  <option value="Europe/London">
                    (GMT+00:00) Europe/London
                  </option>
                  <option value="Europe/Paris">(GMT+01:00) Europe/Paris</option>
                  <option value="Europe/Istanbul">
                    (GMT+03:00) Europe/Istanbul
                  </option>
                  <option value="Asia/Dubai">(GMT+04:00) Asia/Dubai</option>
                  <option value="Asia/Kolkata">(GMT+05:30) Asia/Kolkata</option>
                  <option value="Asia/Bangkok">(GMT+07:00) Asia/Bangkok</option>
                  <option value="Asia/Tokyo">(GMT+09:00) Asia/Tokyo</option>
                  <option value="Australia/Sydney">
                    (GMT+10:00) Australia/Sydney
                  </option>
                  <option value="Pacific/Auckland">
                    (GMT+12:00) Pacific/Auckland
                  </option>
                </select>
              </div>
            </div>
            <div className="editOverlayButton">
              <button
                className="discard"
                onClick={() => {
                  dispatch(storeAction.isPopUpHander());
                }}
              >
                Discard
              </button>

              {loading === false ? (
                <button className="save" onClick={submitbtn}>
                  Submit
                </button>
              ) : (
                <button className="save w-[10rem] flex justify-center items-center">
                  <FiLoader className="loadingIcon" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Table;
