/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "./Pagination";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";

const NewClientFlowtable = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const newclient = useSelector((store) => store.newclient);
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [recordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = alldata.filter((skill) => {
        return skill.first_name.toLowerCase().includes(e.toLowerCase());
      });
      setalldata(matchingSkills);
    } else {
      setalldata(totaldata);
    }
  };
  useEffect(() => {
    Getcandidate();
  }, []);
  const Getcandidate = async () => {
    if (newclient.length !== 0) {
      setloading(false);
      setalldata(newclient);
      settotaldata(newclient);
      var allfacility = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility.companies !== undefined) {
        if (allfacility.companies.length !== 0) {
          var checkdata = await allfacility.companies.filter((data) => {
            return data.status == "New Client";
          });
          setloading(false);
          setalldata(checkdata);
          settotaldata(checkdata);
          dispatch(storeAction.newclientHander({ newclient: checkdata }));
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      var allfacility1 = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility1.companies !== undefined) {
        if (allfacility1.companies.length !== 0) {
          var checkdata1 = await allfacility1.companies.filter((data) => {
            return data.status == "New Client";
          });
          setloading(false);
          setalldata(checkdata1);
          settotaldata(checkdata1);
          dispatch(storeAction.newclientHander({ newclient: checkdata1 }));
        }
      } else {
        setloading(false);
      }
    }
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = alldata.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(alldata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  return (
    <div className="adminProfile paddingLeft100 paddingRight100">
      <DashHead
        head="Onboarding Client"
        desc="View and update profiles of onboarded Client"
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => {
              searchvalue(e.target.value);
            }}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>Client Name</th>
                <th>Location</th>
                <th>Key Skills</th>
                <th>Mobile</th>
                <th>Email</th>
                {/* <th>Resume</th> */}
                {/* <th></th> */}
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        {data.requirements_info !== null ? (
                          <h1>{data.requirements_info.location}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.requirements_info !== null ? (
                          <h1>{data.requirements_info.skills.join(", ")}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>

                      {/* <td>
                        <div className="flex gap-2">
                          <button
                            className="acceptbtn"
                            onClick={() => {
                              acceptbtn(data);
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="rejectbtn"
                            onClick={() => {
                              rejectbtn(data);
                            }}
                          >
                            Reject
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NewClientFlowtable;
