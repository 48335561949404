/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./InterviewComp.css";
import DashHead from "../../../Reusable/DashBoardReusable/DashHead/DashHead";
import SearchProfileCard from "../../../Reusable/SearchProfileCard/SearchProfileCard";
import tabImg from "../../../../assests/table.png";
import tabFirst from "../../../../assests/colar.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../../Store/Store";
import Avatar from "react-avatar";
import moment from "moment";
import CandidateProfileCard from "../../../Reusable/CandidateProfileCard/CandidateProfileCard";
import close from "../../../../assests/billingX.png";
import SingleRange from "../../../MaterialUi/SingleRange/SingleRange";
import brief from "../../../../assests/briefCase.png";
import courseIcons from "../../../../assests/userCard.png";
import userCheck from "../../../../assests/userCheck.png";
import success from "../../../../assests/Succcess.png";

const InterviewComp = (props) => {
  const [isPage, setIsPage] = useState("page1");
  const dispatch = useDispatch();
  const search_user = useSelector((store) => store.searchuser);
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const [month, setmonth] = useState(3);
  const [reserveduser, setreserveduser] = useState([]);
  const [startdate, setstartdate] = useState(moment().format("YYYY-MM-DD"));
  const [searchvalue, setsearchvalue] = useState("");

  const profileData = [
    {
      name: "Surya Narreddi",
      role: "Java Developer",
      Experience: "2 years",
      Skill1: "Java EEE",
      Skill2: "JavaScript",
      Skill3: "Java",
      time: "7:00 PM IST",
      date: "24/12/23",
    },
    {
      name: "Surya Narreddi",
      role: "Java Developer",
      Experience: "2 years",
      Skill1: "Java EEE",
      Skill2: "JavaScript",
      Skill3: "Java",
      time: "7:00 PM IST",
      date: "24/12/23",
    },
    {
      name: "Surya Narreddi",
      role: "Java Developer",
      Experience: "2 years",
      Skill1: "Java EEE",
      Skill2: "JavaScript",
      Skill3: "Java",
      time: "7:00 PM IST",
      date: "24/12/23",
    },
  ];
  const addbookmark = async (id) => {
    let data = JSON.stringify({
      user: userid.toString(),
      bookmarked_user: id.toString(),
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/`,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios
      .request(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    getBookmarkdata();
  };
  const getBookmarkdata = async () => {
    var config1 = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/bookmark/users/${userid}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    var tabledata = await axios(config1)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });

    if (tabledata.length !== 0) {
      const bookmarkedUserArray = tabledata.map((item) => item.bookmarked_user);
      dispatch(
        storeAction.bookmarkdataHander({ bookmarkdata: bookmarkedUserArray })
      );
    }
  };
  const overLayHandler = (e, data) => {
    dispatch(storeAction.isPopUpHander(e));
    setreserveduser([data]);
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const pageHandler = () => {
    setIsPage("page1");
  };

  const overLayHandler1 = async () => {
    let data = JSON.stringify({
      candidate_id: reserveduser[0].id,
      duration: month * 30,
      amount_paid: 15000,
      blocked_by_id: userid,
    });
    var obj_new = {
      username: reserveduser[0].username,
      status: "Reserved",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${reserveduser[0].id}/`,
        obj_new,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...reserveduser[0],
        status: "Reserved",
      };
      setreserveduser([updatedObject]);
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/reservation/blockcandidate/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
    setmonth(3);
    dispatch(storeAction.isPopUpHander("reserveSuccess"));
  };
  const profileRouter = () => {
    setIsPage("page2");
    dispatch(storeAction.isPopUpHander());
    getSearchuser();
  };
  const getSearchuser = async () => {
    if (search_user.length !== 0) {
      dispatch(storeAction.searchuserHander({ searchuser: search_user }));
      var allsearchfacility = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/recentlyvisited/${userid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });

      if (allsearchfacility.recently_visited !== undefined) {
        if (allsearchfacility.recently_visited.length !== 0) {
          var unique = allsearchfacility.recently_visited.filter(
            (value, index, array) => array.indexOf(value) === index
          );
          let data = JSON.stringify({
            users_list: unique,
          });
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/getUsersInformation/${userid}/`,
            headers: {
              Authorization: `JWT ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          var alluserdata = await axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((err) => {
              if (err.response.status === 401) {
                dispatch(storeAction.isloginHandler({ islogin: false }));
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                dispatch(storeAction.tokenHandler({ token: null }));
                dispatch(storeAction.useridHandler({ userid: null }));
                dispatch(storeAction.isPopUpHander(""));
                dispatch(storeAction.searchuserHander({ searchuser: [] }));
                dispatch(storeAction.singleuserHander({ singleuser: [] }));
                dispatch(storeAction.userdataHander({ userdata: [] }));
                dispatch(storeAction.loginroleHander({ islogin: null }));
                dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
                dispatch(storeAction.contractdataHander({ contractdata: [] }));
                window.location.replace("/#/login");
              } else {
                dispatch(storeAction.isloginHandler({ islogin: false }));
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                dispatch(storeAction.tokenHandler({ token: null }));
                dispatch(storeAction.useridHandler({ userid: null }));
                dispatch(storeAction.isPopUpHander(""));
                dispatch(storeAction.searchuserHander({ searchuser: [] }));
                dispatch(storeAction.singleuserHander({ singleuser: [] }));
                dispatch(storeAction.userdataHander({ userdata: [] }));
                dispatch(storeAction.loginroleHander({ islogin: null }));
                dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
                dispatch(storeAction.contractdataHander({ contractdata: [] }));
                window.location.replace("/#/login");
              }
              return err.response;
            });
          if (alluserdata.length !== 0) {
            dispatch(storeAction.searchuserHander({ searchuser: alluserdata }));
          }
        }
      } else {
        dispatch(storeAction.isloginHandler({ islogin: false }));
        dispatch(storeAction.issidebarHandler({ issidebar: false }));
        dispatch(storeAction.tokenHandler({ token: null }));
        dispatch(storeAction.useridHandler({ userid: null }));
        dispatch(storeAction.isPopUpHander(""));
        dispatch(storeAction.searchuserHander({ searchuser: [] }));
        dispatch(storeAction.singleuserHander({ singleuser: [] }));
        dispatch(storeAction.userdataHander({ userdata: [] }));
        dispatch(storeAction.loginroleHander({ islogin: null }));
        dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
        dispatch(storeAction.contractdataHander({ contractdata: [] }));
        window.location.replace("/#/login");
      }
    } else {
      var allsearchfacility = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/recentlyvisited/${userid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allsearchfacility.recently_visited !== undefined) {
        if (allsearchfacility.recently_visited.length !== 0) {
          var unique = allsearchfacility.recently_visited.filter(
            (value, index, array) => array.indexOf(value) === index
          );
          let data = JSON.stringify({
            users_list: unique,
          });
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_LOCAL_HOST_URL}/getUsersInformation/${userid}/`,
            headers: {
              Authorization: `JWT ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          var alluserdata = await axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((err) => {
              if (err.response.status === 401) {
                dispatch(storeAction.isloginHandler({ islogin: false }));
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                dispatch(storeAction.tokenHandler({ token: null }));
                dispatch(storeAction.useridHandler({ userid: null }));
                dispatch(storeAction.isPopUpHander(""));
                dispatch(storeAction.searchuserHander({ searchuser: [] }));
                dispatch(storeAction.singleuserHander({ singleuser: [] }));
                dispatch(storeAction.userdataHander({ userdata: [] }));
                dispatch(storeAction.loginroleHander({ islogin: null }));
                dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
                dispatch(storeAction.contractdataHander({ contractdata: [] }));
                window.location.replace("/#/login");
              } else {
                dispatch(storeAction.isloginHandler({ islogin: false }));
                dispatch(storeAction.issidebarHandler({ issidebar: false }));
                dispatch(storeAction.tokenHandler({ token: null }));
                dispatch(storeAction.useridHandler({ userid: null }));
                dispatch(storeAction.isPopUpHander(""));
                dispatch(storeAction.searchuserHander({ searchuser: [] }));
                dispatch(storeAction.singleuserHander({ singleuser: [] }));
                dispatch(storeAction.userdataHander({ userdata: [] }));
                dispatch(storeAction.loginroleHander({ islogin: null }));
                dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
                dispatch(storeAction.contractdataHander({ contractdata: [] }));
                window.location.replace("/#/login");
              }
              return err.response;
            });
          if (alluserdata.length !== 0) {
            dispatch(storeAction.searchuserHander({ searchuser: alluserdata }));
          }
        }
      } else {
        dispatch(storeAction.isloginHandler({ islogin: false }));
        dispatch(storeAction.issidebarHandler({ issidebar: false }));
        dispatch(storeAction.tokenHandler({ token: null }));
        dispatch(storeAction.useridHandler({ userid: null }));
        dispatch(storeAction.isPopUpHander(""));
        dispatch(storeAction.searchuserHander({ searchuser: [] }));
        dispatch(storeAction.singleuserHander({ singleuser: [] }));
        dispatch(storeAction.userdataHander({ userdata: [] }));
        dispatch(storeAction.loginroleHander({ islogin: null }));
        dispatch(storeAction.bookmarkdataHander({ bookmarkdata: [] }));
        dispatch(storeAction.contractdataHander({ contractdata: [] }));
        window.location.replace("/#/login");
      }
    }
  };
  return (
    <>
      <div className="dashBoardMain paddingLeft100">
        {isPage === "page2" ? (
          <>
            <div className="displayHandler">
              <CandidateProfileCard
                main="candidateProfile"
                fun={pageHandler}
                back="candidateBack"
                setreserveduser={setreserveduser}
              />
            </div>
            {/* <div className="displayHandlerMob">
          <MobileCandidateProfile />
        </div> */}
          </>
        ) : (
          <div className="paddingRight100">
            <DashHead
              head="Interviews"
              desc="All your interviews kept in one place. If you need instant help in scheduling an interview,"
              highLight="Contact us"
              descClass="dashBoardMainHeadDescBetween"
            />
            <h1 className="interviewHead">Upcoming interviews</h1>
            <div className="">
              {userdata[0].interview_info.length !== 0 ? (
                <div className="">
                  <div className={props.class}>
                    <div className="innerTable">
                      <table className="table">
                        <tr className="tableHead">
                          <th>Candidate Name</th>
                          <th>Qualification</th>
                          <th>Experience</th>
                          <th>Key Skills</th>
                          <th>Date</th>
                          <th>Time</th>
                        </tr>
                        {userdata[0].interview_info.map((data) => {
                          return moment().format("YYYY-MM-DD") <= data.date ? (
                            data.candidate.length !== 0 ? (
                              <tr className="tableRow">
                                <td>
                                  <div className="profileData ">
                                    {data.candidate[0].profile_picture
                                      .length !== 0 ? (
                                      <img
                                        src={data.candidate[0].profile_picture}
                                        alt=""
                                        className="mr-2 interviewimg"
                                      />
                                    ) : (
                                      <Avatar
                                        name={data.candidate[0].first_name}
                                        size={30}
                                        round="50px"
                                        className="mr-2"
                                      />
                                    )}
                                    <h2>{data.candidate[0].first_name}</h2>
                                  </div>
                                </td>
                                <td>
                                  <h2>
                                    {
                                      data.candidate[0].preference_info
                                        .qualification
                                    }
                                  </h2>
                                </td>
                                <td>
                                  <h2>
                                    {
                                      data.candidate[0].preference_info
                                        .year_of_experience
                                    }{" "}
                                    Years
                                  </h2>
                                </td>
                                <td className="skillData1">
                                  <>
                                    {data.candidate[0].preference_info.skills
                                      .length !== 0
                                      ? data.candidate[0].preference_info.skills.map(
                                          (datanew, index) =>
                                            index == 0 ||
                                            index == 1 ||
                                            index == 2 ? (
                                              <p key={index}>{datanew}</p>
                                            ) : null
                                        )
                                      : null}
                                  </>
                                </td>

                                <td>
                                  <h2>{data.date}</h2>
                                </td>
                                <td>
                                  <h2>{data.time}</h2>
                                </td>
                              </tr>
                            ) : null
                          ) : null;
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}

              <h1 className="interviewHead">
                Schedule call with your shortlisted candidates
              </h1>
              <div className="interviewCard">
                {search_user.length !== 0
                  ? search_user.map((datanew, index1) => (
                      <div className="recentWrap" key={index1}>
                        <SearchProfileCard
                          datanew={datanew}
                          addbookmark={addbookmark}
                          reserve={overLayHandler}
                          setIsPage={setIsPage}
                        />
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        )}
      </div>
      {isPopUp === "reserve" && (
        <div className="reserveCandidate">
          <div className="reserveHead">
            <h1>Reserve candidate</h1>
            <img
              src={close}
              alt=""
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
            />
          </div>
          {reserveduser.length !== 0 ? (
            <div className="reserveCandidateFlex">
              <div className="reserveCandidateFlexLeft">
                <h2>What is reserving?</h2>
                <ul>
                  <li>
                    By paying the Reserve Fees, the candidate will be{" "}
                    <span className="darkHighter">
                      blocked for you exclusively for a period of 5 days.
                    </span>{" "}
                    This feature is available only to Pro Subscribers.
                  </li>
                  <li>
                    During the 5 days, our{" "}
                    <span className="darkHighter">
                      {" "}
                      Customer Success team will work to ensure{" "}
                    </span>
                    that you are able to sign the engagement contract for the
                    candidate.
                  </li>
                  <li>
                    The amount paid towards reserving the candidate will be
                    <span className="darkHighter">
                      {" "}
                      adjusted against the first invoice
                    </span>{" "}
                    against the same candidate.
                  </li>
                  <li>
                    Start date of the engagement for a candidate who has been
                    "Reserved" on the system, cannot be beyond than 30 days.
                  </li>
                  <li>
                    After reserving a candidate, in case you are unable to
                    complete the signing formalities within 5 days, the
                    candidate's profile will be automatically opened up for
                    others to hire / reserve, and the reserve fees shall be
                    refunded to you within 3-4 working days.
                  </li>
                  <li>
                    For any reason whatsoever, if the candidate shortlisted by
                    you is not available, you will have the choice to either get
                    the refund or use that credit against the next invoice.
                  </li>
                </ul>
              </div>
              <div className="reserveCandidateFlexRight">
                <div className="reserveCandidateFlexRightHead">
                  <div className="reserveCandidateFlexRightHeadLeft">
                    {/* <img src={profile} alt="" /> */}
                    <Avatar
                      name={reserveduser[0].first_name}
                      size={50}
                      round="50px"
                    />
                    <div className="reserveCandidateFlexRightHeadLeftDesc">
                      <h3>{reserveduser[0].first_name}</h3>
                      {reserveduser[0].preference_info !== null ? (
                        <h4>{reserveduser[0].preference_info.qualification}</h4>
                      ) : null}
                    </div>
                  </div>
                  <div className="reserveCandidateFlexRightHeadRight">
                    {reserveduser[0].rate_card_info !== null ? (
                      <h5>
                        USD
                        {reserveduser[0].rate_card_info.remote_hourly}/hr
                      </h5>
                    ) : (
                      <h5 className="rateHour">Not provided yet</h5>
                    )}
                  </div>
                </div>
                {reserveduser[0].preference_info !== null ? (
                  <>
                    {" "}
                    <div className="candidateCartSkills">
                      {reserveduser[0].preference_info.skills.length !== 0
                        ? reserveduser[0].preference_info.skills.map(
                            (data, index) => (
                              <h4 key={index}>
                                <img src={courseIcons} alt="" />
                                {data}
                              </h4>
                            )
                          )
                        : null}
                    </div>
                    <div className="reserveCandidateBrief">
                      <h6 className="briefH5">
                        <img src={brief} alt="" />
                        <p>
                          {reserveduser[0].preference_info.year_of_experience}{" "}
                          years of experience{" "}
                        </p>
                      </h6>
                      <h6 className="briefH5">
                        <img src={userCheck} alt="" />
                        {reserveduser[0].work_preference_info !== null ? (
                          <p>
                            {
                              reserveduser[0].work_preference_info
                                .preferred_mode_of_engagement
                            }{" "}
                            availability
                          </p>
                        ) : null}
                      </h6>
                    </div>
                  </>
                ) : null}

                <h2>Start date</h2>
                <input
                  type="date"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                  max={moment().add(30, "days").format("YYYY-MM-DD")}
                  defaultValue={startdate}
                />
                <h2 className="marginBottom35">Duration of engagement</h2>
                <SingleRange setmonth={setmonth} />
                <div className="durationmMonths">
                  <h4>3 months</h4>
                  <h4>1 year</h4>
                </div>
                <h5>
                  Candidate will be reserved from{" "}
                  {startdate !== null ? (
                    startdate.length !== 0 ? (
                      <span className="darkHighter">
                        {moment(startdate).format("MMM DD, YYYY")} -{" "}
                        {moment(startdate)
                          .add(month, "month")
                          .format("MMM DD, YYYY")}
                      </span>
                    ) : (
                      <span className="darkHighter">
                        {moment().format("MMM DD, YYYY")} -{" "}
                        {moment().add(month, "month").format("MMM DD, YYYY")}
                      </span>
                    )
                  ) : (
                    <span className="darkHighter">
                      {moment().format("MMM DD, YYYY")} -{" "}
                      {moment().add(month, "month").format("MMM DD, YYYY")}
                    </span>
                  )}
                </h5>
                <div className="fees">
                  <h4>Reserve Fees</h4>
                  <h4>$ 500</h4>
                </div>
                <button onClick={overLayHandler1}>Continue to Payment</button>
                <p>
                  You’ll be taken to{" "}
                  <span className="darkHighter">Razorpay</span> to complete the
                  transaction
                </p>
              </div>
            </div>
          ) : null}
        </div>
      )}
      {isPopUp === "reserveSuccess" && (
        <div className="reserveSuccess">
          <div className="reserveSuccessClose">
            {/* <img className="closeImage" src={close} alt="" /> */}
          </div>
          <img src={success} alt="" />
          <h1>Candidate reserved</h1>
          <p>Our customer success team will contact you shortly! </p>
          <button onClick={profileRouter}>Back to Candidate</button>
        </div>
      )}
    </>
  );
};

export default InterviewComp;
