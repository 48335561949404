/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import search from "../../assests/search.png";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "./Pagination";
import axios from "axios";
import DashHead from "../Reusable/DashBoardReusable/DashHead/DashHead";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeAction } from "../../Store/Store";
import { useDispatch } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";

const NewCandidatetable = () => {
  const dispatch = useDispatch();
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };
  const newcandidate = useSelector((store) => store.newcandidate);
  const token = useSelector((store) => store.token);
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [recordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(true);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [loadingnew, setloadingnew] = useState(false);
  const [loadingnew1, setloadingnew1] = useState(false);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = alldata.filter((skill) => {
        return skill.first_name.toLowerCase().includes(e.toLowerCase());
      });
      setalldata(matchingSkills);
    } else {
      setalldata(totaldata);
    }
  };
  useEffect(() => {
    Getcandidate();
  }, []);
  const Getcandidate = async () => {
    if (newcandidate.length !== 0) {
      setalldata(newcandidate);
      setloading(false);
      settotaldata(newcandidate);
      var allfacility = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility.faculties !== undefined) {
        if (allfacility.faculties.length !== 0) {
          var checkdata = await allfacility.faculties.filter((data) => {
            return (
              data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send"
            );
          });
          setloading(false);
          setalldata(checkdata);
          settotaldata(checkdata);
          dispatch(storeAction.newcandidateHander({ newcandidate: checkdata }));
        }
      } else {
        setloading(false);
      }
    } else {
      setloading(true);
      var allfacility1 = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (allfacility1.faculties !== undefined) {
        if (allfacility1.faculties.length !== 0) {
          var checkdata1 = await allfacility1.faculties.filter((data) => {
            return (
              data.status == "New Candidate" ||
              data.status == "Success" ||
              data.status == "Reject" ||
              data.status == "Mail send"
            );
          });

          setloading(false);
          setalldata(checkdata1);
          settotaldata(checkdata1);
          dispatch(
            storeAction.newcandidateHander({ newcandidate: checkdata1 })
          );
        }
      } else {
        setloading(false);
      }
    }
  };
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = alldata.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(alldata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const acceptbtn = async (data) => {
    setloadingnew(true);
    var notification = {
      email: data.email,
      subject: "Action Required: Complete Your Application Today!",
      message: `
      <p>Dear ${data.first_name},</p>
      <p>We are delighted to process your application. Please click on the link below to complete your application:</p>
      <p><a href="https://stage.hirein5.com/#/createpassword/${data.email}">Complete Your Application</a></p>
      <p>Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Mail send",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    Getcandidate();
    var allfacility1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (allfacility1.faculties !== undefined) {
      if (allfacility1.faculties.length !== 0) {
        var checkdata1 = await allfacility1.faculties.filter((data) => {
          return (
            data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send"
          );
        });
        setloading(false);
        setalldata(checkdata1);
        settotaldata(checkdata1);
        dispatch(storeAction.newcandidateHander({ newcandidate: checkdata1 }));
      }
    } else {
      setloading(false);
    }
    setTimeout(() => {
      setloadingnew(false);
      settoastifystatus(false);
    }, 2000);
  };
  const rejectbtn = async (data) => {
    setloadingnew1(true);
    var newobj = {
      email: data.email,
      username: data.email,
      status: "Reject",
    };
    var notification = {
      email: data.email,
      subject: "Application Update!",
      message: `<p>Thank you for your interest in Hirein5. We regret to inform you that we are unable to process your application at this stage. We shall be in touch for other openings. <br>All the best, Team Hirein5</p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, notification, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    Getcandidate();
    settoastifystatus(true);
    toast.success("Email Sent Successfully...", {
      autoClose: 2000,
      transition: Slide,
      style: customToastStyle,
    });
    setloadingnew1(false);
    setTimeout(() => {
      settoastifystatus(false);
    }, 2000);
  };
  return (
    <div className="adminProfile paddingLeft100 paddingRight100">
      <DashHead
        head="Onboarding Candidate"
        desc="View and update profiles of onboarded candidates"
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => {
              searchvalue(e.target.value);
            }}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>Candidate Name</th>
                <th>Location</th>
                <th>Key Skills</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Resume</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading === false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.profile_picture.length !== 0 ? (
                            <img src={data.profile_picture} alt="" />
                          ) : (
                            <Avatar
                              name={data.first_name}
                              size={30}
                              round="50px"
                            />
                          )}

                          <h1>{data.first_name}</h1>
                        </div>
                      </td>
                      <td>
                        {data.new_candidate_info !== null ? (
                          <h1>{data.new_candidate_info.location}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.new_candidate_info !== null ? (
                          <h1>{data.new_candidate_info.skills.join(", ")}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        <h1>{data.phone}</h1>
                      </td>
                      <td>
                        <h1>{data.email.toLowerCase()}</h1>
                      </td>
                      <td>
                        <h1
                          className="font-medium text-blue-500 cursor-pointer"
                          onClick={() => {
                            window.open(
                              `${
                                data.new_candidate_info !== null
                                  ? data.new_candidate_info.resume
                                  : null
                              }`,
                              "_blank"
                            );
                          }}
                        >
                          View
                        </h1>
                      </td>
                      <td>
                        {data.status == "Success" ? (
                          <p className="hiringActive px-2 rounded-full">
                            Reviewed
                          </p>
                        ) : data.status == "Reject" ? (
                          <p className="rejectstaus px-2 rounded-full">
                            On Hold
                          </p>
                        ) : data.status == "New Candidate" ? (
                          <p className="contracted px-2 rounded-full">New</p>
                        ) : data.status == "Mail send" ? (
                          <p className="processingstaus px-2 rounded-full">
                            Processing
                          </p>
                        ) : (
                          <p className="hiringActive px-2 rounded-full">-</p>
                        )}
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {data.status == "Success" || data.status == "Mail send" ? null : loadingnew ==
                            false ? (
                            <button
                              className="acceptbtn"
                              onClick={() => {
                                acceptbtn(data);
                              }}
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            <button className="acceptbtn">
                              <FiLoader className="loadingIcon" />
                            </button>
                          )}
                          {loadingnew1 === false ? (
                            <button
                              className="rejectbtn"
                              onClick={() => {
                                rejectbtn(data);
                              }}
                            >
                              X
                            </button>
                          ) : (
                            <button className="rejectbtn">
                              <FiLoader className="loadingIcon" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default NewCandidatetable;
