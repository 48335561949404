/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./CandidateProfile.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import countryimg1 from "../../../../assests/Vector.png";
import star from "../../../../assests/star.svg";
import gallery from "../../../../assests/gallery.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { storeAction } from "../../../../Store/Store";
import { FiLoader } from "react-icons/fi";
import moment from "moment";

const Candidatepreview = () => {
  const dispatch = useDispatch();
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const [isPage, setIsPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitbtn = async () => {
    setIsLoading(true);
    var obj = {
      username: userdata[0].username,
      nottify: true,
    };
    var newobj = {
      message: `<p><b>${userdata[0].first_name}</b> has completed their profile to a 100%</p>`,
      status: "false",
      on_type: "Candidate Profile Completion",
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });

    var emailobj = {
      email: userdata[0].username,
      subject: "Thanks for completing your profile!",
      message:
        "Your profile is now complete! Get ready for tailored job opportunities that match your skills and aspirations.",
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    var email_obj = {
      email: "connect@hirein5.com",
      subject: "New Notification: Check Your Dashboard!",
      message:
        "You have a new notification waiting for you! Log in to your dashboard now to stay updated and take action on the latest opportunities.",
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, email_obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${userdata[0].id}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      setIsLoading(false);
      let updatedObject = {
        ...userdata[0],
        nottify: updatedata.user.nottify,
      };
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(
          storeAction.userdataHander({
            userdata: [updatedObject],
          })
        );
        window.location.replace("/#/profile");
      }, 10);
    }
  };

  return (
    <div>
      <div className="profilePage paddingLeft100 paddingRight100">
        <div
          className="headingdiv"
          onClick={() => {
            window.location.replace("/#/profile");
          }}
        >
          <IoIosArrowRoundBack className="backicon" />
          <h3>Preview</h3>
        </div>

        {isPage === false &&
          (userdata.length !== 0 ? (
            <div className="">
              <div className="personalDetailsDesc">
                <h1>Personal Details</h1>
                {userdata.length !== 0 ? (
                  <div className="personalInfo">
                    <div className="personalDetailsDescLeft">
                      <h2>Name</h2>
                      <h3>{userdata[0].first_name}</h3>
                      <h2>Date of Birth</h2>
                      <h3>
                        {moment(userdata[0].date_of_birth).format("DD-MM-YYYY")}
                      </h3>
                      <h2>Phone Number</h2>
                      <h3>{userdata[0].phone}</h3>
                      <h2>Gender</h2>
                      <h3>{userdata[0].gender}</h3>
                      {userdata[0].kyc_info !== null ? (
                        <>
                          <h2>AADHAAR / Govt. Issued ID</h2>
                          <h3 className="personalDetailsDescLeftOpacity">
                            {userdata[0].kyc_info.aadhar_number}
                          </h3>
                          <h2>PAN Card / Govt. Issued TAX ID</h2>
                          <h3 className="personalDetailsDescLeftOpacity">
                            {userdata[0].kyc_info.pan_number}
                          </h3>
                        </>
                      ) : null}
                    </div>
                    <div className="personalDetailsDescRight">
                      <h2>Email ID</h2>
                      <h3>{userdata[0].email}</h3>
                      {userdata[0].address !== null ? (
                        <>
                          <h2>Current Residential Address</h2>
                          <h3>{userdata[0].address.address}</h3>
                          <h2>City</h2>
                          <h3>{userdata[0].address.city}</h3>
                          <h2>PINCODE</h2>
                          <h3>{userdata[0].address.pincode}</h3>
                          <h2>Country</h2>
                          <h3>{userdata[0].address.country}</h3>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {userdata.length !== 0 ? (
                  <>
                    <h2 className="bioLabel">Bio</h2>
                    <h3 className="biocontent">{userdata[0].bio}</h3>
                  </>
                ) : null}
              </div>
              {userdata[0].work_preference_info !== null ? (
                <div className="workExperienceDesc mt-5">
                  <div className="workExperience1">
                    <h3>Work Experience Information</h3>
                    <div className="workExperience2">
                      <div className="workExperienceleft">
                        <div className="workTopskills">
                          <h4>Top Skills</h4>
                          <div className="workbtns">
                            {userdata[0].work_preference_info.key_skills
                              .length !== 0
                              ? userdata[0].work_preference_info.key_skills.map(
                                  (data, index) => (
                                    <button key={index}>{data}</button>
                                  )
                                )
                              : null}
                          </div>
                        </div>
                        <div className="workTopskills">
                          <h4>Current Employment Status</h4>
                          <h2>
                            {userdata[0].work_preference_info
                              .current_employment_status.length !== 0
                              ? userdata[0].work_preference_info
                                  .current_employment_status
                              : "Not Provided"}
                          </h2>
                        </div>
                        {/* need to be removed */}
                        <div className="workTopskills">
                          <h4>Preferred mode of engagement</h4>
                          <h2>
                            {" "}
                            {userdata[0].work_preference_info
                              .preferred_mode_of_engagement.length !== 0
                              ? userdata[0].work_preference_info
                                  .preferred_mode_of_engagement
                              : "Not Provided"}
                          </h2>
                        </div>
                        <div className="workTopskills">
                          <h4>Preferred method of working</h4>
                          <h2>
                            {userdata[0].work_preference_info.method_of_work.every(
                              (item) => item.trim() === ""
                            )
                              ? "Not Provided"
                              : userdata[0].work_preference_info.method_of_work.join(
                                  ", "
                                )}
                          </h2>
                        </div>
                        <div className="workTopskills">
                          <h4>Preferred Work Timings</h4>
                          <h5>
                            {userdata[0].work_preference_info
                              .preffered_work_timings.length !== 0
                              ? userdata[0].work_preference_info
                                  .preffered_work_timings
                              : "Not Provided"}
                          </h5>
                        </div>
                        <div className="workTopskills">
                          <h4>Languages known</h4>
                          {userdata[0].preference_info.language.length !== 0
                            ? userdata[0].preference_info.language.map(
                                (data, index) => (
                                  <div
                                    className="worklanguages flex gap-4"
                                    key={index}
                                  >
                                    <h2 title="">
                                      {data.split(":")[0]} -{" "}
                                      <p> {data.split(":")[1]}</p>
                                    </h2>
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </div>
                      <div className="workExperienceright">
                        <div className="workTopskills">
                          <h4>Website URL</h4>
                          <h5>
                            {userdata[0].work_preference_info.website_url}
                          </h5>
                        </div>
                        <div className="workTopskills">
                          <h4>Technical Assessment (or Equivalent Score)</h4>
                          <h5>
                            {userdata[0].work_preference_info.hackerrank_url}
                          </h5>
                        </div>
                    
                        <div className="workTopskills">
                          <h4>GitHub</h4>
                          <h5>{userdata[0].work_preference_info.github_url}</h5>
                        </div>
                        <div className="workTopskills">
                          <h4>Preferred Work Locations</h4>
                          {userdata[0].work_preference_info
                            .preffered_work_location.length !== 0
                            ? userdata[0].work_preference_info.preffered_work_location.map(
                                (data, index) => (
                                  <h2 key={index}>
                                    <img src={countryimg1} alt="" />
                                    {data}
                                  </h2>
                                )
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="professionalDetailsDesc mt-5">
                <h1>Professional Experience</h1>
                {userdata[0].professional_details_info.length !== 0 ? (
                  userdata[0].professional_details_info.map((data, index) => (
                    <div className="professionalDetailsDesc" key={index}>
                      <h2>{data.title}</h2>
                      <h3>{data.company_name}</h3>
                      <h4>
                        {data.years_active.split(",")[0]} -{" "}
                        {data.years_active.split(",")[1]}
                      </h4>
                      <h4>{data.location}</h4>
                      <h5>{data.description}</h5>
                      <h6>
                        Key Skills :{" "}
                        <span className="professionalDetailsDescSkills">
                          {data.skills.join(", ")}{" "}
                        </span>
                      </h6>
                      <h6>
                        Gross Annual Salary : {data.currency} -{" "}
                        {data.annual_salary}{" "}
                      </h6>
                      <div className="projectDetailsHighlight">
                        <img src={star} alt="" />
                        <p>
                          Did you know that highlighting more projects enhances
                          your credibility and attracts the attention of
                          potential employers?
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="professionalDetailsDesc">
                    <h4 className="text-center">Fresher</h4>
                  </div>
                )}
              </div>
              <div className="professionalDetailsDesc mt-5">
                <h1>Project Details</h1>
                {userdata[0].project_details_info.length !== 0 ? (
                  userdata[0].project_details_info.map((data, index) => (
                    <div className="projectDetailsDesc" key={index}>
                      <h2>{data.project_title}</h2>
                      <div className="projectDetailsDescFlex">
                        <h3>Role : </h3>
                        <p>{data.role}</p>
                      </div>
                      <div className="projectDetailsDescFlex">
                        <h3>Reporting to : </h3>
                        <p>{data.reporting_to}</p>
                      </div>
                      <div className="projectDetailsDescFlex">
                        <h3>Duration : </h3>
                        <p>{data.duration_of_project} </p>
                      </div>
                      <div className="projectDetailsDescFlexLast">
                        <h4>Key Skills:</h4>
                        <p>{data.skills.join(", ")}</p>
                      </div>
                      <h6>{data.description}</h6>
                      <div className="projectDetailsHighlight">
                        <img src={star} alt="" />
                        <p>
                          Did you know that highlighting more projects enhances
                          your credibility and attracts the attention of
                          potential employers?
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="professionalDetailsDesc">
                    <h4 className="text-center">Fresher</h4>
                  </div>
                )}
              </div>
              <div>
                {userdata[0].certificate_info.length !== 0 ? (
                  <div className="certificateDesc mt-5">
                    <h1>Certification / Course Details</h1>
                    <div>
                      {userdata[0].certificate_info.map((data, index) => (
                        <div className="innerCertificateDesc mb-3" key={index}>
                          <h2>{data.course_name}</h2>
                          <div className="certificateDescFlex">
                            <h3>Date Issued:</h3>
                            <p>
                              {moment(data.date_issued).format("DD-MM-YYYY")}
                            </p>
                          </div>
                          <div className="certificateDescFlex">
                            <h3>Certificate Url (Optional)</h3>
                            <p>{data.url}</p>
                          </div>
                          <div className="certificateDescFlexLast">
                            <h4>Key Skills:</h4>
                            <p>{data.skills.join(", ")}</p>
                          </div>
                          <p>{data.description}</p>
                          {data.certificate_file.length !== 0
                            ? data.certificate_file.map((data, index) =>
                                data.length !== 0 ? (
                                  <div
                                    className="gradeCertificate"
                                    onClick={() => {
                                      window.open(`${data}`, "_blank");
                                    }}
                                    key={index}
                                  >
                                    <img src={gallery} alt="" />
                                    <div className="gradeCertificateDesc">
                                      <h2>
                                        {
                                          data
                                            .split("/images/")[1]
                                            .split("/")[1]
                                        }
                                      </h2>
                                    </div>
                                  </div>
                                ) : null
                              )
                            : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="professionalDetailsDesc mt-5">
                <h1>Travel Details</h1>
                {userdata[0].travel_info !== null
                  ? userdata[0].travel_info.travelled_to.length !== 0
                    ? userdata[0].travel_info.travelled_to.map(
                        (data, index) => (
                          <div className="travelGridOne" key={index}>
                            <h1>Countries you’ve travelled to</h1>
                            <h2>{data.split(":")[0]}</h2>
                            <h3>
                              Year of Travel : <p>{data.split(":")[1]}</p>
                            </h3>
                            <h3 className="marginBottom20">
                              Duration : <p>{data.split(":")[2]}</p>
                            </h3>
                            <h3>
                              Purpose : <p>{data.split(":")[3]}</p>
                            </h3>
                            <h3>
                              Type of Visa : <p>{data.split(":")[4]}</p>
                            </h3>
                            <h3>
                              Validity of Visa :{" "}
                              <p>
                                {moment(data.split(":")[5]).format(
                                  "DD-MM-YYYY"
                                )}
                              </p>
                            </h3>
                          </div>
                        )
                      )
                    : null
                  : null}
              </div>
              <div className="professionalDetailsDesc mt-5">
                <h1>Education Details</h1>
                {userdata[0].education_info.length !== 0
                  ? userdata[0].education_info.map((data, index) => (
                      <div className="educationDesc" key={index}>
                        <h2>{data.degree}</h2>
                        <div className="educationDescFlex">
                          <h3>Name of University/School : </h3>
                          <p>{data.university_name} </p>
                        </div>
                        <div className="educationDescFlex">
                          <h3>Year of Graduation : </h3>
                          <p>{data.year_of_graduation}</p>
                        </div>
                        <div className="educationDescFlex">
                          <h3>Education Level : </h3>
                          <p>{data.education_level} </p>
                        </div>
                        <div className="educationDescFlex">
                          <h4>Study Mode : </h4>
                          <p>{data.study_mode}</p>
                        </div>
                        <div className="educationDescFlexLast">
                          <h4>CGPA : </h4>
                          <p>{data.cgpa}%</p>
                        </div>
                        {data.upload_file.length !== 0
                          ? data.upload_file.map((data, index) =>
                              data.length !== 0 ? (
                                <div
                                  className="gradeCertificate"
                                  onClick={() => {
                                    window.open(`${data}`, "_blank");
                                  }}
                                  key={index}
                                >
                                  <img src={gallery} alt="" />
                                  <div className="gradeCertificateDesc">
                                    <h2 style={{ fontSize: "14px" }}>
                                      {data.split("/images/")[1].split("/")[1]}
                                    </h2>
                                  </div>
                                </div>
                              ) : null
                            )
                          : null}
                      </div>
                    ))
                  : null}
              </div>
              <div className="professionalDetailsDesc mt-5">
                <h1>Video Resume</h1>
                {userdata[0].video_resume.length !== 0 ? (
                  <div className="videoResumeDesc">
                    <div className="video">
                      <video controls>
                        <source
                          src={userdata[0].video_resume}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>

                    <div className="vedioNotes mt-5">
                      <img src={star} alt="" />
                      <div className="notes">
                        <h4>
                          Here are a few basic pointers to help you get started
                        </h4>
                        <ol>
                          <li>Choose a non-distracting background</li>
                          <li>
                            Ensure the lighting is flattering and the sound is
                            clear.
                          </li>
                          <li>
                            Dress professionally and maintain eye contact with
                            the camera
                          </li>
                          <li>
                            The Video Resume should be recorded only in English
                          </li>
                          <li>Aim for a length of 1-2 minutes for the video</li>
                          <li>
                            Do not record in HD or UHD formats as the file size
                            would be large.
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null)}
        <div className="vedioResumeButtons">
          <button
            className="discard"
            onClick={() => {
              window.location.replace("/#/profile");
            }}
          >
            Keep Editing
          </button>

          {isLoading === false ? (
            <button className="save mt-5 mb-5" onClick={submitbtn}>
              Submit for Review
            </button>
          ) : (
            <button className="save mt-5 mb-5 flex gap-2">
              <span className="text-[#f4f4f5]">Loading...</span>
              <FiLoader className="loadingIcon" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Candidatepreview;
